.link {
  margin-left: 5px;
  color: #02a3a4;
  font-size: 11px;
  text-decoration: underline;
}

.registerWrapper {
  margin-top: 20px;
}

.bottomTextWrapper {
  margin-top: 8px;
}

.registerText {
  color: #909bad;
  font-size: 10.5px;
}

.registerLink {
  color: #02a3a4;
  font-size: 10.5px;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .registerText {
    max-width: 100%;
  }
}
