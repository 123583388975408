.receiptWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 20px;
  background-color: #f8f8f8;

  h5 {
    font-weight: bold;
    color: #9fa9ba;
    display: block;
    margin: 0 0 5px 0;
    font-size: 14px;
  }
}

.receiptContainer {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding: 100px 30px 30px 30px;
  margin-top: 30px;
  box-shadow: 0 2px 29px 0 rgba(159, 169, 186, 0.37);
  position: relative;

  &.noPadding {
    padding: 30px;
  }
}

.receiptRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #f8f8f8;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .questions,
  .policies {
    flex: 1;
    margin: 0 50px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    h5 {
      margin-bottom: 15px;
    }
  }

  .policies {
    ul {
      vertical-align: top;
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0 30px 0 0;

      li {
        margin-bottom: 10px;
        a {
          font-weight: 500;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .infoText {
    color: #9fa9ba;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    margin-top: 4px;
    letter-spacing: 0;
    span {
      margin: 0 8px 0 0;
      position: relative;
      top: 2px;
    }
  }
}

.sellerPicture {
  margin-top: -140px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 15px;
  font-weight: 900;
  color: #9fa9ba;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 2px 8px rgba(159, 169, 186, 0.37);
  }
}

.nameAndTags {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;

  h4.productName {
    color: #ff2b80;
    margin: 0 20px 0 0;
  }

  .tags {
    :global {
      .bp3-tag {
        margin-right: 5px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.productImage {
  width: 210px;
  height: 210px;
  background-color: #f8f8f8;
  border-radius: 5px;
  object-fit: cover;
}

.purchaseInfo {
  flex: 1;
  margin-left: 30px;

  h1 {
    color: #9fa9ba;
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
  }
}

.orderNumber {
  position: absolute;
  top: 0px;
  right: 0px;

  p {
    margin: 0;
    padding: 4px 7px;
    background-color: #9fa9ba;
    border-radius: 0 5px 0 5px;
    letter-spacing: 1.5px;
    font-weight: 500;
    font-size: 19px;
    text-align: center;
    color: #fff;
  }
}

.purchaseDetails {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .itemDescription,
  .dateOfPurchase,
  .methodOfPayment {
    flex: 0.5;
  }

  .quantity {
    flex: 0.25;
  }

  .amount {
    flex: 0.25;
  }

  .customerNote {
    flex: 1;
    p {
      word-break: break-word;
    }
  }
}

@media (max-width: 850px) {
  .productName {
    flex: 1;
  }

  .productImage {
    width: 100%;
  }

  .receiptRow {
    flex-direction: column;

    .policies,
    .purchaseInfo {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}
