.formRow {
  display: flex;
  align-items: center;

  &:global {
    & > .bp3-form-group {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.agreementDetail {
  border: 1px solid rgb(233, 239, 244);
  border-radius: 3px;
  margin-top: 2px;
  label {
    font-size: 12px;
    color: #000;
  }
  ul {
    margin: 0;
    padding: 10px;
    list-style: disc;
    li {
      list-style: disc;
      font-size: 13px;
      color: #000;
      line-height: 1.7;
      margin-left: 16px;
    }
  }
}

.fldSelect {
  :global{
    .bp3-html-select{
      width:100%;
      height:40px !important;
      select {
        height:100% !important;
      }
      .bp3-icon-double-caret-vertical{
        top:50% !important;
        transform:translateY(-50%) !important;
      }
    }
  }
}
