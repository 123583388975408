body {
  background-color: #f5f8fa;
}

#root {
  display: grid;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

#root,
body {
  height: 100vh;
  min-height: 100vh;
}

@media (max-width: 1400px) {
  #root {
    grid-template-areas: "main";
    grid-template-columns: 1fr;
  }
}

@media (max-width: 800px) {
  #root,
  body {
    height: initial;
    min-height: initial;
  }
}
