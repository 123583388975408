.container {
  display: flex;
  flex-direction: column;

  .imageWrapper {
    align-self: flex-start;
  }

  .header {
    font-size: 32px;
    color: #2e2e2e;
    line-height: 1.21;
  }

  .paragraph {
    font-size: 18px;
    line-height: 1.75;
    color: #2e2e2e;
  }

  .registrationImage {
    width: 145px;
  }
}

@media (max-width: 1024px) {
  .container {
    margin: 0;
    width: 100%;
    height: auto;
    align-items: flex-start;
    .imageWrapper {
      align-self: center;
    }
  }
}
