.shareSheet {
  min-width: 300px;

  h5 {
    font-size: 18px;
    margin: 0 0 0.5em 0;
    word-break: break-word;
  }

  .thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 3px;
    margin: 0 0 0.5em 0;
  }

  .embed {
    font-size: 13px;
    font-family: "SF Mono", "Consolas", monospace;
    height: 100px;
    resize: none;
  }

  .fixedVideoOptions {
    display: flex;

    span {
      font-weight: 500;
      font-size: 16px;
      padding: 2px 10px;
    }

    .optionInput {
      width: 45px;
    }
  }

  .socialMediaButtons {
    display: flex;

    .socialMediaButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      border-radius: 3px;
      border: 1px solid transparent;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .thumbnail {
    flex: 1;
    display: flex;
    position: relative;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    border-radius: 3px;
    padding: 10px;
    margin: 0 0 1em 0;
    cursor: pointer;

    h2 {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 23.5px;
      padding: 10px;
      margin: 0;
      font-size: 14px;
    }
  }
}

.drawer {
  height: fit-content !important;
  padding: 30px !important;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #5d5d5d;
}

.withColorHover {
  &:active,
  &:focus,
  &:hover,
  span {
    color: #fff !important;
  }
}

@media (min-width: 1100px) {
  .shareSheet {
    h5 {
      width: 95%;
    }
  }
}
