.reviewPurchase {
  $textColor: #909bad;
  $mutedTextColor: #adbdcb;
  $borderColor: #e9eff4;

  height: 100%;
  .mainHeading {
    text-align: center;
    padding: 18px 0 2px;
    span {
      font-size: 10px;
      color: $mutedTextColor;
    }
  }
  .informationBox {
    display: flex;
    flex-direction: row;
    padding: 17px 12px 17px 0;
    border-top: 1px solid $borderColor;
    .detailInfo {
      flex: 1;
      span {
        color: $mutedTextColor;
        font-size: 10px;
      }
      p {
        color: $textColor;
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 0;
        line-height: 18px;
      }
    }
    .arrowIcon {
      align-self: center;
      align-items: center;
    }
  }
  .finalReceipt {
    border-top: 1px solid $borderColor;
    background-color: rgb(248, 250, 251);
    padding: 17px 16px;
    margin: 0 -16px;

    span {
      font-size: 10px;
      color: $mutedTextColor;
    }
    .flexRow {
      display: flex;
      flex-direction: row;
      .leftColumn {
        flex: 1;
      }
      .rightColumn {
        text-align: right;
        .boldText {
          font-weight: 600;
        }
      }
    }
    p {
      color: $textColor;
      font-size: 13px;
      margin: 0;
      line-height: 20px;
    }

    .grandTotal {
      margin-top: 20px;
      p {
        margin-top: 0;
      }
    }
  }
}
.leftCol {
  flex: 0.4;
  margin-right: 10px;
}
.rightCol {
  flex: 1;
}
.quantitySelector {
  width: 100%;
  position: relative;
  cursor: pointer;
  .quantityBtn {
    background-color: #909bad;
    padding: 11px;
    width: 100%;
    text-transform: uppercase;
    color: white;
    border: none;
    border-radius: 21.5px;
  }
  select {
    width: 100%;
    height: 39px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
