.purchaseComplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  h1,
  p {
    text-align: center;
  }

  h1 {
    font-size: 20px;
  }

  p {
    margin: 10px 0;
    line-height: 20px;
  }

  .stars {
    margin-bottom: 20px;
  }

  .returnToVideo {
    width: 100%;
    max-width: 165px;
    margin-top: 10px;
  }
}
