$progress-base: #f36568;
.player {
  position: relative;
  height: 540px;
  background: #b78572;
  overflow: hidden;

  .liveStreamTimer {
    background: #252525;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;

    .info {
      margin: 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.6;
      padding-top: 35px;
      text-align: center;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    z-index: 10;
  }

  .actions {
    position: absolute;
    width: 100%;
    opacity: 0.5;
    background-color: #000000;
    height: 50px;
    top: 0;
    right: 0;
    padding: 0px 7px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      color: #fff;
    }
    button {
      color: #fff;
    }
  }
  .menuButton {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    margin: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 22px;
    z-index: 10;
    display: none;
    svg {
      color: #fff;
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 12px;
    width: 100%;
    opacity: 0.65;
    background-color: #000000bf;
    color: #ffffff;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      color: #fff;
    }
  }
  .videoProgress {
    flex-grow: 1;
    position: relative;
    margin: 0px 5px;
    display: flex;
  }
  .bar {
    position: absolute;
    width: 100%;
    height: 7px;
    transition: opacity 0.2s;
    cursor: pointer;
    color: #f36568;
    z-index: 20;
    pointer-events: none;
    border-radius: 50px;
    overflow: hidden;

    &::-moz-progress-bar {
      background: $progress-base;
    }
    &::-webkit-progress-value {
      background: $progress-base;
    }
  }
  .progressBar {
    flex-grow: 1;
    background: #c0c5c9;
    height: 7px;
    transition: opacity 0.2s;
    cursor: pointer;
    -webkit-appearance: none;
    position: relative;
    border-radius: 50px;
    &::-webkit-slider-thumb {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      cursor: pointer;
      position: sticky;
      background: white;
      -webkit-appearance: none;
      z-index: 200 !important;
      visibility: visible;
      z-index: 30;
      margin-left: 1px;
    }
    &::-moz-range-thumb {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      cursor: pointer;
      position: sticky;
      background: white;
      -webkit-appearance: none;
      z-index: 200 !important;
      visibility: visible;
      z-index: 30;
      margin-left: 1px;
    }
    &::-ms-thumb {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      cursor: pointer;
      position: sticky;
      background: white;
      -webkit-appearance: none;
      z-index: 200 !important;
      visibility: visible;
      z-index: 30;
      margin-left: 1px;
    }
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    filter: blur(30px);
    z-index: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}

.hud {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  margin-top: -48px;
  margin-left: -48px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: scale(0);
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}
.actionBtns {
  display: flex;
  flex-direction: column;
}
.actionTarget {
  transform: rotate(90deg);
}
@media (max-width: 1400px) {
  .menuButton {
    display: block !important;
  }
}

@media (max-width: 1100px) {
  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;

    .actions {
      top: 10px;
      width: auto;
      height: auto;
      background: none;
      opacity: 1;
    }
  }
}
