.circleWrapper {
  border-radius: 14px;
  text-align: center;
  background-color: #cad8e4;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.circleNumber {
  color: #e9eff4;
}

.currentStep {
  background-color: #909bad;
}

.label {
  flex-grow: 1;
  color: #7a869b;
  display: flex;
  align-items: center;
}

.rightStep {
  cursor: default;
  background-color: #ffffff;
  border: 1px solid #cad8e4;
}
