.checkoutStep {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.checkoutContent {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  -webkit-overflow-scrolling: touch;
}

.checkoutInfo {
  background-color: rgb(248, 250, 251);
  padding: 20px;

  p {
    margin: 0;
    font-size: 17px;
    line-height: 22px;
    color: rgb(173, 189, 203);
    text-align: center;
  }

  &.small {
    padding: 15px;

    p {
      font-size: 13px;
    }
  }

  &.legible {
    border-bottom: 1px solid #f8fafb;
    background-color: #fff;
    border-top: 1px solid #f8fafb;

    p {
      color: #000;
      text-align: left;
    }
  }
}

.checkoutActions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.checkoutButton {
  height: 38px;
  border-radius: 19px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(101, 207, 161);
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.73px;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;

  &.primary {
    background-color: #ff2b80;
  }

  &:active {
    background-color: rgb(65, 167, 123);
  }

  &[disabled] {
    background-color: #909bad;
    opacity: 0.5;
    cursor: default;

    &:active {
      background-color: #909bad;
    }
  }
}
