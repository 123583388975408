.root {
  grid-area: main;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .gridContainer {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .leftGridItem {
      flex: 1;
      padding: 72px;
      max-width: 554px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rightGridItem {
      padding: 72px;
      flex: 1;
    }

    .leftGridItem {
      background-color: #f8fafb;
      height: 590px;
    }
  }
}

@media (max-width: 1024px) {
  .root {
    .gridContainer {
      padding: 15px;
      flex-direction: column;
      max-width: 500px;
      width: 100%;

      .leftGridItem,
      .rightGridItem {
        padding: 20px;
        width: 100%;
      }
    }
  }
}
