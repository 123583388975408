.wrapper {
  width: 100%;
  height: 72px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f8fa;

  .iconWrapper {
    margin-left: 33px;
    background-color: #fff;
    padding: 14px;
    border-radius: 50%;
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 32px;

    .detail {
      margin: 0;
    }
  }
}
