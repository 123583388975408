.bgImage {
  display: flex;
  position: absolute;
  height: 60%;
  width: 100%;
  z-index: -10;
  overflow: hidden;

  img {
    flex-grow: 1;
    object-fit: cover;
  }
}

.page {
  max-width: 1060px;
  margin: 0 auto;
  padding: 50px;
}

.card {
  border-radius: 0px !important;
  margin: 15px 0px;
}

.header {
  padding: 0px 0px 20px 0px;
  display: flex;

  .picture {
    width: 40px;
    height: 40px;
    background: #ced9e0;
    border-radius: 50%;
    margin: 0 10px 0 0;
    object-fit: cover;
    cursor: pointer;
  }

  h1 {
    font-size: 18px;
  }

  h1,
  p {
    margin: 0;
  }

  label {
    font-size: 12px;
    font-weight: 600;
  }
}

.flex1 {
  flex-grow: 1;
}

.info {
  padding: 5px 25px 20px 25px;

  .row {
    display: flex;
    flex-grow: 1;
  }

  .videoInfo {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .segment {
      margin-right: 20px;
      width: 50%;

      .gallery {
        width: 256px;
        height: 170px;
        object-fit: cover;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);

        &:not(:first-child) {
          width: 120px;
          height: 84px;
          margin: 5px;
          object-fit: cover;
        }
      }

      .basicInfo {
        margin: 1.5em 0 0 0;

        h4 {
          font-size: 16px;
          margin: 0;
        }
      }

      .description {
        border-radius: 3px;
        padding-top: 16px;
        margin: 0 0 2em 0;
        font-size: 12px;
        color: #8096a7;

        p {
          margin: 0;
          font-size: 12px;
          color: #909bad;
          line-height: 21px;
          word-spacing: 2px;
        }
      }
    }
  }

  .securityLogo {
    margin-top: 20px;
  }

  .checkout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .flex {
    flex: 1;
  }

  h1 {
    font-size: 18px;
  }

  h1,
  p {
    margin: 0;
  }

  h2 {
    font-size: 15px;
  }

  label {
    font-size: 12px;
    font-weight: 600;
  }
}

.infoWrapper {
  display: flex;
  width: 100%;
}

.buy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 60px;
  margin: 0 0 0 30px;
  background: linear-gradient(137deg, #ff2b80, #ff6b6b);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    background: rgba(92, 112, 128, 0.6);
  }

  &:hover {
    background: #ff2b80;
  }
}

.buyNow {
  margin-right: 3px;
}

@media (max-width: 1100px) {
  .page {
    padding: 0;
  }

  .info {
    border-radius: 5px 0 0 0;
    display: flex;
    position: fixed;
    bottom: 35px;
    right: 0;
    z-index: 8;
    padding: 10px;
    border-radius: 2px;
    width: 100%;
    justify-content: space-evenly;

    .row {
      background-color: white;
      padding: 10px;
    }

    .videoInfo {
      .segment {
        width: 100%;
      }

      .carousel {
        margin: 0 auto;

        :global {
          .carousel {
            height: 250px;
          }
        }
      }
    }

    .productInfo {
      display: none;
    }

    .securityLogo {
      display: none;
    }
  }

  .buy {
    height: 36px;
    margin-left: 13px;
    padding: 10px 18px 8px 19px;
    font-size: 12px;
    &[disabled] {
      pointer-events: none;
      background: rgba(92, 112, 128, 0.6);
    }
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    background: none;
    width: auto;
    height: auto;
    top: 0;
    padding: 10px 40px 10px 10px;
    z-index: 10;
    pointer-events: none;
    left: 0;
    background: #0000002e;
    right: 0;

    .picture {
      width: 32px;
      height: 32px;
    }
    h1 {
      height: 21px;
      color: white !important;
    }
    label {
      color: white !important;
      text-transform: uppercase;
      letter-spacing: 0.28px;
      height: 14px;
      display: block;
      margin-top: 5px;
    }
  }

  .buyNow {
    display: none;
  }

  .detailOpen {
    padding: 0 !important;
    position: initial !important;
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
    margin-bottom: 1px !important;

    .row {
      flex-direction: row !important;
    }
  }
  .details {
    position: absolute;
    width: 100%;
    bottom: 50px;
    right: 0px;
    padding: 0 7px;
    .image {
      height: 54px;
      width: 54px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 3px;
    }

    .row {
      display: flex;
      width: 100%;
      padding: 10px;
      background: white;
      align-items: center;
      border-radius: 3px;
      &.collapsed {
        background: rgba(255, 255, 255, 0.7);
      }

      .productName {
        flex-grow: 1;
        overflow-x: hidden;
        cursor: pointer;
        align-items: center;
        display: flex;
        h4 {
          margin: 0;
          line-height: 1.04;
          justify-content: center;
          align-items: center;
          word-break: break-word;
        }
      }
    }
  }
  .mediumDetail {
    border-radius: 0px !important;

    .info {
      display: block;
      position: initial;
      z-index: 8;
      padding: 0px;
      width: 100%;
      justify-content: space-evenly;
      flex-direction: column;
      overflow: auto;
    }

    .row {
      background-color: white;
      padding: 10px;
      flex-direction: column;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 42px;
        height: 42px;
        margin-right: 5px;
        object-fit: cover;
      }
    }

    .videoInfo {
      flex: 1 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .gallery {
        width: 100%;
        height: 170px;
        object-fit: cover;
        cursor: pointer;

        &:not(:first-child) {
          width: 120px;
          height: 85px;
          margin: 5px;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1100px) and (min-width: 450px) {
  .info {
    .videoInfo {
      flex-direction: row;

      .segment {
        margin-left: 20px;
      }
    }
  }
}

.closeButton {
  position: absolute;
  right: 15px;
  top: -35px;
  color: #ffffff;
}

.heading1 {
  height: auto !important;
}

@media (max-width: 500px) {
  .heading1 {
    font-size: 13px !important;
  }

  .productName {
    font-size: 13px !important;
  }

  .image {
    width: 36px !important;
    height: 36px !important;
  }

  .details {
    .row {
      padding: 8px !important;
    }
  }
}
