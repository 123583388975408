.linkPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  overflow-x: hidden;

  #background-color {
    background-color: #e9eff4;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
  }

  .background {
    position: absolute;
    top: 450px;
    width: 100%;
    z-index: -1;
  }

  .illustrationContainer {
    width: 100%;
    overflow-x: hidden;
    text-align: center;
    margin-top: -150px;
    height: 470px;
    z-index: -1;
    background-image: url("./redirect_car.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .illustration {
    width: 909px;
    z-index: -1;
  }

  .title {
    padding-top: 50px;
    font-size: 26px;
    max-width: 338px;
    text-align: center;
    color: #9fa9ba;
    font-weight: 900;
  }

  p {
    max-width: 370px;
    text-align: center;
    padding-top: 19px;
    margin: 0;
    font-size: 15px;
    color: #909bad;

    a {
      color: #ff2b80;
    }
  }

  .buttonContainer {
    flex-direction: row;
    padding-top: 30px;

    a:first-child {
      padding-right: 20px;
    }
  }
}
