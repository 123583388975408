.selectedVariant {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f8fafb;
  padding-bottom: 70px;
  margin-bottom: -70px;
  z-index: -1;

  .variantImage {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    object-fit: cover;
  }

  .variantInfo {
    margin-left: 12px;

    p {
      color: #9fa9ba;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .selectedOptions {
    display: flex;
    align-items: center;

    &:global {
      & > .bp3-tag {
        margin-right: 5px;
      }
    }
  }
}

.donationCheckout {
  .donationOptionList {
    padding: 0 0 2rem 0;
    label {
      color: #909bad;
      font-weight: 400;
      font-size: 12px;
    }
    .donationOptionSet {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0px;
      }

      .donationValues {
        button {
          width: 85px;
          margin: 15px 15px 0 0;
          border: 1px solid #e9eff4;
          border-radius: 9px;
          color: #909bad;
          padding: 10px 16px;
          line-height: 22px;
          font-size: 17px;
          text-transform: none;
          text-decoration: none;
          background-color: transparent;
          &:hover {
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.12);
          }
          &.selected {
            border: 1.5px solid #adbdcb;
            background-color: #f8fafb;
          }
          &[disabled] {
            border: 1px dashed #e9eff4;
            color: #e9eff4;
            opacity: 30%;
          }
        }
      }
    }
  }
}

.invalidDonation {
  color: red;
  font-size: 0.9em;
  display: block;
  margin-top: -11px;
}
