.videoInfoPopover {
  height: 250px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer {
  padding: 30px !important;
}

.videoInfo {
  width: 100%;
  min-width: 300px;
  overflow: auto;

  .basicInfo {
    margin: 0 0 1.5em 0;

    h4 {
      font-size: 18px;
      margin: 0;
      word-break: break-word;
    }
  }

  .publisherBio {
    background-color: #f5f8fa;
    border-radius: 3px;
    padding: 1em;
    margin: 0 0 2em 0;
    height: 200px;
    overflow: auto;

    p {
      margin: 0;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .publisherInfo,
  .productInfo {
    display: flex;
    align-items: center;

    img {
      width: 42px;
      min-width: 42px;
      height: 42px;
      border-radius: 50%;
      margin: 0 1em 0 0;
      object-fit: cover;
    }

    p {
      margin: 0;
      color: #909bad;
    }

    p.name {
      flex: 1;
      font-weight: 500;
      color: #000;
    }
  }

  .publisherInfo {
    margin: 0 0 1em 0;
  }

  .productInfo {
    border-top: 3px solid #ff6b6b;
    box-shadow: 0 1px 10px #cad8e4;
    border-radius: 3px;
    padding: 1em;
    margin: 0 0 1.5em 0;

    img {
      border: 1px solid #cad8e4;
    }

    *:last-child {
      margin: 0 0 0 3px;
      padding: 0 20px;
    }
  }

  hr {
    height: 2px;
    border-radius: 3px;
    border: none;
    background-color: #cad8e4;
    margin: 1.5em 0;
  }

  .help {
    display: flex;
    align-items: center;
    padding: 0.5em 1em 0.5em 1.2em;
    border: 1px dashed #cad8e4;
    border-radius: 3px;

    p {
      margin: 0 0 0 1em;

      small {
        color: #909bad;
      }
    }
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #5d5d5d;
  }
}

.withColorHover {
  &:active,
  &:focus,
  &:hover,
  span {
    color: #fff !important;
  }
}

@media (min-width: 1100px) {
  .videoInfo {
    .basicInfo {
      h4 {
        width: 95%;
      }
    }
  }
}
