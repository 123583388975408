.settings {
  padding: 34px 51px;
  background-color: #fff;

  .panel {
    @media (min-width: 640px) {
      max-width: 412px;
      margin-left: 80px;
    }

    h4 {
      margin: 0 0 5px 0;
      line-height: 30px;

      &:not(:first-child) {
        margin-top: 45px;
        padding-top: 30px;
        border-top: 1px #ced9e0 solid;
      }

      & + p {
        margin: 0 0 43px 0;
      }
    }

    p {
      color: #8096a7;
      line-height: 20px;
      margin: 20px 0 25px 0;
    }

    .inputGroup {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: row;

      :global {
        .bp3-form-group {
          flex: 1;

          &:not(:first-child) {
            margin-left: 8px;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }

    .buttonGroup {
      margin-top: 40px;
      display: flex;
      flex: 1;

      :global {
        .bp3-button {
          &:not(:first-child) {
            margin-left: 5px;
          }

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
