.header {
  color: #ff6b6b;
  font-size: 34px;
  margin: 0;
}

.buttonTextWrapper {
  display: inline;
  position: relative;
  right: 8px;
}

.iconWrapper {
  position: relative;
  left: 5px;
  display: inline;
}

.icon {
  color: white !important;
}

.backButton {
  border-radius: 50% !important;
  width: 61px;
  height: 61px;
  background-color: #cad8e4 !important;
  position: relative;
  top: 14px;
  margin-right: 6px;
}

.groups {
  margin-top: 28px;

  & > div:last-child {
    margin-bottom: 0;
  }

  label {
    color: #909bad;
    margin-left: 5px;
    font-size: 11px;
  }

  input {
    height: 45px;
  }
}

button.button[class*="bp3-button"] {
  background-color: #02a3a4;
  border-radius: 30px;
  border: none;
  padding: 0;
  margin-top: 30px;

  & > span {
    color: white;
    font-weight: bold;
    font-size: 23px;
    padding: 15px 37px;
  }

  &:hover,
  &:active,
  &:disabled {
    background-color: #008081;
  }

  &:disabled {
    opacity: 0.5;
  }
}

@media (max-width: 1024px) {
  .root {
    padding: 0;
    width: 100%;
  }

  .groups input {
    width: 100% !important;
  }
}
