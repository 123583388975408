.errorPage {
  background-color: #f6f8fa;
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  .errorIcon {
    margin: 62px auto;
    position: relative;
    z-index: 5;
    img {
      width: 100%;
      padding: 0 10px;
    }
  }

  .errorMessage {
    max-width: 774px;
    padding: 0 16px;
    position: relative;
    z-index: 5;

    .heading {
      font-size: 48px;
      font-weight: 900;
      letter-spacing: normal;
      color: #000000;
      margin: 0 0 24px;
    }

    .description {
      font-size: 18px;
      line-height: 1.44;
      color: #8096a7;
    }
  }
}
