.formRow {
  display: flex;
  align-items: center;

  &:global {
    & > .bp3-form-group {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.selectDropdown {
  :global {
    .bp3-html-select {
      width: 100%;
      height: 40px !important;
      select {
        height: 100% !important;
      }
      .bp3-icon-double-caret-vertical {
        top: 50% !important;
        transform: translateY(-50%) !important;
      }
    }
  }
}

.postalCode {
  flex: 0.5;
}

// $lightGray: #adbdcb;
// $darkGray: #9fa9ba;

// .shippingInfo {
//   padding: 0 12px;
//   margin-top: 2rem;
//   border-top: 1px solid #e9eff4;
//   label {
//     font-size: 10px;
//     color: $lightGray;
//     padding-top: 7pt;
//   }
//   .formField {
//     .title {
//       margin-top: 8px !important;
//       padding-top: 0 !important;
//     }
//     .inputIcon {
//       height: 15px;
//       width: 15px;
//       position: absolute;
//       top: 4px;
//       right: 15px;
//     }
//     input {
//       width: 100%;
//       box-shadow: none !important;
//       outline: none !important;
//       border-radius: 0;
//       font-size: 17px;
//       color: $darkGray;
//       border: none !important;
//       margin: 3px 0 0 !important;
//       padding: 0 32px 15px 0 !important;
//       letter-spacing: 0.48pt;
//       border-bottom: 1px #f0f0f1 solid !important;

//       &::placeholder {
//         color: $lightGray;
//       }
//     }
//   }
//   .stepContainer {
//     margin-top: 1em;
//     .stepBtn {
//       background-color: rgb(101, 207, 161);
//       padding: 11px;
//       width: 100%;
//       text-transform: uppercase;
//       color: white;
//       border: none;
//       border-radius: 21.5px;
//       &:disabled {
//         background-color: #cad8e4;
//       }
//     }
//     @media only screen and (max-width: 480px) {
//       position: sticky;
//       bottom: 0;
//       width: 100%;
//     }
//   }
//   .flexRow {
//     display: flex;
//     margin: 0 -10px 0 -10px;
//     .flexColumn {
//       width: 50%;
//       padding: 0 10px;
//     }
//     .flexFirstColumn {
//       width: 50%;
//       padding: 0 10px;
//     }
//     .flexSecColumn {
//       width: 20%;
//       padding: 0 5px;
//     }
//     .flexThirdColumn {
//       width: 30%;
//       padding: 0 10px;
//     }
//   }
// }
