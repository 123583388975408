.expirationAndCvv {
  display: flex;
  align-items: center;

  .expiration {
    flex: 1;
    margin-right: 15px;
  }

  .cvv {
    flex: 1;
  }
}

.cvvInfo {
  display: flex;
  & > p,
  & > div {
    margin: 0 0 0 15px;
  }
}

.securityBadges {
  text-align: center;
  margin-top: 30px;
}

.nmiInputContainer {
  position: relative;
  display: block;
  &.formLoading {
    height: 42px;
    :disabled {
      cursor: wait;
    }
  }
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  padding: 1px;
  border-radius: 3px;

  iframe {
    padding-left: 40px;
    padding-right: 10px;
  }

  &.cvvInput {
    iframe {
      padding-right: 40px;
      padding-left: 10px;
    }
    :global {
      .bp3-popover-wrapper {
        position: absolute;
        margin: 8px;
        right: 0;
      }
    }
  }
  &.cardInput {
    iframe {
      padding-right: 40px;
      padding-left: 40px;
    }
    :global {
      .bp3-popover-wrapper {
        position: absolute;
        margin: 5px;
        right: 0;
        top: 0;
      }
    }
  }
}
